import { isAndroid, osName, osVersion } from 'react-device-detect';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import NavBar from '../components/commons/NavBar';
import { useAuth } from '../components/auth/AuthProvider';
import logo from '../assets/logo.svg';
import packageJSON from '../../package.json';

export const Account = () => {
  const { logout } = useAuth();
  const { version } = packageJSON;

  const isOldAndroid: boolean = isAndroid && parseInt(osVersion) < 12;
  const typeOfOsVersion: string = typeof osVersion;
  const osVersionString: string = `(${typeOfOsVersion.charAt(0)}${isOldAndroid ? '*' : ''})`;

  return (
    <div className="container-box-main">
      <img src={logo} className="logo" alt="logo" />
      <div className="container-box">
        <div className="logout-box">
          <h1>Profilo</h1>
          <center>
            {/* <h3> ID evento</h3> */}
            <h3 style={{ textTransform: 'uppercase' }}> {sessionStorage.getItem('username')}</h3>
            {/* <p className="slogan-text"> Piazza di Brescia</p> */}
          </center>
          <Button variant="contained" startIcon={<Logout />} color="error" onClick={() => logout()}>
            Logout
          </Button>
        </div>
      </div>
      <div>
        <p className="slogan-text"> Rendiamo il cancro sempre più curabile</p>
        <p className="version">V {version}</p>
        <p className="version">
          {osName} {osVersion} {osVersionString}
        </p>
      </div>
      <NavBar />
    </div>
  );
};
