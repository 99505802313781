import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, TextField } from '@mui/material';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { ISummaryFormData } from '../../interface/summary';

interface SummaryTextFieldAccordionToProps {
  accordionSummaryTitle: string;
  control: Control<ISummaryFormData, any>;
  disabled?: boolean;
}

export default function SummaryTextFieldAccordionTo({
  control,
  accordionSummaryTitle,
  disabled,
}: SummaryTextFieldAccordionToProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qt_date_piazze_esterne',
  });

  return (
    <Accordion
      sx={{
        backgroundColor: 'transparent',
        border: '1px solid rgba(118, 118, 118, 0.5)',
        boxShadow: 'none',
        borderRadius: 1,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{accordionSummaryTitle}</AccordionSummary>
      <AccordionDetails>
        {fields.map((field, index) => (
          <div
            key={field.id}
            style={{
              display: 'flex',
              gap: '0.8rem',
              marginTop: '0.7rem',
            }}
          >
            <Controller
              key={`qt_date_piazze_esterne.${index}.nome`}
              name={`qt_date_piazze_esterne.${index}.nome`}
              control={control}
              disabled={disabled}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    size="small"
                    label="Nome Piazza"
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
            <Controller
              key={`qt_date_piazze_esterne.${index}.quantità`}
              name={`qt_date_piazze_esterne.${index}.quantità`}
              control={control}
              disabled={disabled}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  label="Quantità"
                  sx={{
                    flex: 1,
                  }}
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        ))}
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={() => remove(Math.max(1, fields.length - 1))} disabled={fields.length === 1 || disabled}>
          Cancella
        </Button>
        <Button onClick={() => append({ nome: '', quantità: '' })} disabled={disabled}>
          Aggiungi
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
