import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Disclaimer = () => {
  const navigate = useNavigate();
  return (
    <div className="disclaimer-box">
      <h1> ATTENZIONE!</h1>
      <p>Gentile volontario,</p>
      <p>
        nello svolgimento della tua attività di volontariato, per cui ti ringraziamo sin d’ora, è per te necessario
        trattare dati personali dei nostri donatori e sostenitori.
        <br />
        Pertanto,{' '}
        <u>
          Fondazione AIRC per la ricerca sul cancro ETS, in qualità di Titolare del trattamento dei dati personali ai
          sensi del GDPR, ti autorizza a trattare dati personali di donatori e sostenitori della Fondazione nei limiti
          di quanto necessario allo svolgimento delle attività richieste e nel rispetto delle seguenti istruzioni
        </u>
        :
      </p>
      <ul>
        <li>
          Custodire, mantenere segrete e utilizzare con attenzione e diligenza le proprie credenziali di autenticazione
          alla presente banca dati;
        </li>
        <li>
          raccogliere i soli dati personali indicati nei form predisposti da AIRC, avendo cura di fornire sempre
          l’informativa sul loro trattamento;
        </li>
        <li>non richiedere e raccogliere dati di minorenni;</li>
        <li>
          in caso di perdita, furto, compromissione dei dati, dei dispositivi o delle credenziali avvisare
          immediatamente il proprio referente AIRC;
        </li>
        <li>
          non salvare/copiare alcun dato personale acquisito nello svolgimento delle attività assegnate su propri o
          altrui device personali;
        </li>
        <li>
          trattare i dati con riservatezza ed esclusivamente per lo svolgimento dei compiti assegnati, evitando
          qualsiasi utilizzo per finalità personali;
        </li>
        <li>
          evitare di attingere la connettività con cui collegarsi ai sistemi da Wi-Fi altrui, da hot spot sconosciuti o
          pubblici;
        </li>
        <li>
          in caso di raccolta di dati personali in forma cartacea controllare e custodire diligentemente i documenti che
          contengono i dati, avendo cura di restituirli quanto prima ad AIRC.
        </li>
      </ul>
      <p>
        Si ricorda che i volontari legittimati alla raccolta dei dati dei donatori, secondo le istruzioni sopra
        indicate, sono solo quelli che hanno ricevuto e sottoscritto la presente nomina. Ai soli Responsabili di piazza
        è riconosciuta la facoltà di incaricare altri volontari a trattare i dati personali dei donatori, trasferendo
        loro le istruzioni ricevute.
      </p>
      <Button
        variant="contained"
        type="submit"
        fullWidth
        onClick={() => {
          sessionStorage.setItem('conferma_riservatezza', 'si');
          navigate('/');
        }}
      >
        Ho compreso e mi impegno a rispettare le istruzioni
      </Button>
    </div>
  );
};
