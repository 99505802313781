import { FormHelperText, TextField } from '@mui/material';
import { ProductToBuy } from '../../../interface/product';
import styles from './DonazioneCents.module.css';
import { useEffect, useState } from 'react';

interface DonazioneCentsProps {
  product: ProductToBuy;
  onIncrementQuantity: (id: number, amount: number) => void;
}

export default function DonazioneCents({ onIncrementQuantity, product }: DonazioneCentsProps) {
  const [centsCount, setCentsCount] = useState<string>('00');

  useEffect(() => {
    onIncrementQuantity(product.id, Number(centsCount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centsCount]);

  return (
    <div className={styles.container_cents}>
      <div className={styles.container_cents_input}>
        <span>+ 0,</span>
        <TextField
          sx={{ marginInline: 0.4, marginTop: 0.4 }}
          inputProps={{
            style: {
              width: 21,
              height: 15,
              fontSize: 19,
              fontWeight: 500,
            },
            maxLength: 2,
            inputMode: 'numeric',
          }}
          onBlur={() => (isNaN(Number(centsCount)) || centsCount === '' ? setCentsCount('00') : null)}
          value={centsCount}
          onChange={(e) => setCentsCount(e.target.value)}
          size="small"
          variant="standard"
        />
        <span>€</span>
      </div>
      {isNaN(Number(centsCount)) ? (
        <FormHelperText sx={{ color: 'red' }}>Inserisci un valore numerico</FormHelperText>
      ) : null}
    </div>
  );
}
