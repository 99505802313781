import { useCallback } from 'react';
import { OCRResult } from '../interface/ocr';
import { createSearchParams, useNavigate } from 'react-router-dom';

export const useOcrRedirect = () => {
  const navigate = useNavigate();

  const resolveOcrRedirect = useCallback(
    (ocrData: OCRResult) => {
      switch (ocrData.responseType) {
        case 'FOUND_ONE':
          navigate(
            {
              pathname: '/update-contact',
              search: createSearchParams({
                id: String(ocrData.id),
              }).toString(),
            },
            { state: ocrData.receipt && { receipt: ocrData.receipt } },
          );
          break;
        case 'FOUND_MANY':
          const { query } = ocrData;
          navigate(
            {
              pathname: '/results-people',
              search: createSearchParams({
                ...query,
              }).toString(),
            },
            { state: ocrData.receipt && { receipt: ocrData.receipt } },
          );
          break;
        case 'NOT_FOUND':
          const { data } = ocrData;
          navigate(
            {
              pathname: '/create-contact',
              search: createSearchParams({
                ...data,
              }).toString(),
            },
            { state: ocrData.receipt && { receipt: ocrData.receipt } },
          );
          break;
      }
    },
    [navigate],
  );

  return { resolveOcrRedirect };
};
