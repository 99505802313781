import React, { ReactNode } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { toast } from 'react-toastify';
import { Product } from '../../interface/product';
import { useAuth } from '../auth/AuthProvider';
import { searchProducts } from '../../api/searchProducts';

type ProductProviderProps = {
  children: ReactNode;
};

export function useProducts() {
  const { token } = useAuth();

  const { data, isLoading } = useQuery<Product[], Error>('products', () => searchProducts(token), {
    staleTime: 24 * 3600 * 1000, // 24 hours
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  return {
    products: data || [],
    isLoading,
  };
}

const queryClient = new QueryClient();

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
