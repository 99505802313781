import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="back-button cursor-pointer" onClick={() => navigate(-1)}>
      <ChevronLeftIcon /> Indietro
    </div>
  );
};
