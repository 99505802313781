import { ProdottoDonazione } from '../../interface/donazione';

interface Props {
  prodotto: ProdottoDonazione;
}

const DonazioneItem: React.FC<Props> = ({ prodotto }) => {
  return (
    <p>
      Prodotto:{' '}
      <strong>
        {prodotto.quantita} x {prodotto.nome_prodotto} = {prodotto.importo} €
      </strong>
    </p>
  );
};

export default DonazioneItem;
