/* eslint-disable max-lines */
import { Box } from '@mui/material';
import NavBar from '../components/commons/NavBar';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RendicontazioniButton from '../components/commons/RendicontazioniButton';
import { useQuery } from 'react-query';
import { useAuth } from '../components/auth/AuthProvider';
import { fetchSummary } from '../api/fetchSummary';
import { SkeletonReport } from '../components/commons/Skeleton';

export const RendicontazioneMenu = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const { data, isLoading } = useQuery(['getRendicontazione'], () => fetchSummary(token), {
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const navigateIfRendicontazioneExists = (path: string) => {
    if (!data && !isLoading) {
      toast.error("Creare l'allegato B prima di procedere alla compilazione di queste sezioni");
      return;
    }
    navigate(path);
  };

  return (
    <div className="container-main" style={{ overflowY: 'scroll' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <h1>Consuntivo punto distribuzione</h1>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height="100%"
        padding={2}
        gap={2}
        overflow="y"
      >
        {isLoading ? (
          <SkeletonReport />
        ) : (
          <>
            <RendicontazioniButton
              onClick={() => navigate('summary')}
              label="Rendiconto - Allegato B"
              icon={<ReceiptLongOutlinedIcon />}
            />
            <RendicontazioniButton
              onClick={() => navigateIfRendicontazioneExists('ddt')}
              label="Documento di trasporto"
              icon={<LocalShippingOutlinedIcon />}
            />
            <RendicontazioniButton
              onClick={() => navigateIfRendicontazioneExists('merce-danneggiata')}
              label="Merce danneggiata"
              icon={<SentimentVeryDissatisfiedOutlinedIcon />}
            />
          </>
        )}
      </Box>
      <NavBar />
    </div>
  );
};
