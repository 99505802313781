import styles from './PhotoButton.module.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

interface PhotobuttonProps {
  onClick: () => void;
}

export default function Photobutton({ onClick }: PhotobuttonProps) {
  return (
    <div className={styles.photo_button} onClick={onClick}>
      <div className={styles.circle}>
        <PhotoCamera
          sx={{
            width: '70%',
            height: '70%',
            color: '#42a6fc',
          }}
        />
      </div>
      <div className={styles.ring}></div>
    </div>
  );
}
