import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';

type ConfirmModalProps = {
  open: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  backButtonText?: string;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    open,
    title,
    subtitle,
    text,
    backButtonText,
    confirmButtonText,
    onCancel = () => {},
    onConfirm = () => {},
  } = props;

  return (
    <Dialog open={open} onClose={onCancel} style={{ maxWidth: '500px', margin: '0 auto' }}>
      {title && <DialogTitle textAlign="center">{title}</DialogTitle>}
      {subtitle && (
        <DialogTitle variant="h2" textAlign="center">
          {subtitle}
        </DialogTitle>
      )}
      {text && (
        <DialogContent style={{ paddingTop: 20 }}>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
        {backButtonText && (
          <Button onClick={onCancel} color="primary" variant="outlined">
            {backButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button onClick={onConfirm} color="primary" variant="contained">
            {confirmButtonText}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
