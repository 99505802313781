import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createContact } from '../api/createContact';
import { useAuth } from '../components/auth/AuthProvider';
import { SubmitOptions, UpsertForm } from '../components/contacts/UpsertForm';
import { schemaUpsertContact } from '../components/validation/schemaUpsertContact';
import { Contact, ContactExtended } from '../interface/contact';
import { useContact } from '../components/contacts/ContactProvider';
import { useState } from 'react';
import ConfirmModal from '../components/commons/ConfirmModal';
import { SkeletonForm } from '../components/commons/Skeleton';

function removeNullOrEmptyValues(obj: ContactExtended) {
  const { id, consenso_marketing, ...rest } = obj;
  return Object.fromEntries(
    Object.entries(rest).filter(([key, value]) => value !== null && value !== undefined && value !== ''),
  );
}

export const CreateContact = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setContact } = useContact();
  const [lastUpdateContact, setLastUpdateContact] = useState<number>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const data_nascita_param = searchParams.get('data_nascita');

  const searchCriteria: Partial<Contact> = {
    nome: searchParams.get('nome') || '',
    cognome: searchParams.get('cognome') || '',
    codice_fiscale: searchParams.get('codice_fiscale') || '',
  };

  if (data_nascita_param) {
    searchCriteria.data_nascita = new Date(data_nascita_param);
  }

  const createContactForm = useForm({
    resolver: yupResolver(schemaUpsertContact, { stripUnknown: true }),
    defaultValues: {
      ...searchCriteria,
    },
  });

  const { mutate: mutateCreate, isLoading } = useMutation<Contact, Error, Contact>((data: Contact) => {
    return createContact(data, token);
  });

  const onSubmit = (data: any, options: SubmitOptions) => {
    const cleanedData: Contact = removeNullOrEmptyValues(data);

    mutateCreate(cleanedData, {
      onSuccess: (createData) => {
        toast.success('Contatto creato con successo');
        if (options?.next === 'createDonazione') {
          setContact(createData || null);
          queryClient.invalidateQueries('contact');
          queryClient.invalidateQueries('contacts');
          navigate('/create-donazione', { state: location.state?.receipt && { receipt: location.state?.receipt } });
        } else {
          setLastUpdateContact(createData?.codice_contatto);
          setContact(null);
          setConfirmOpen(true);
        }
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const handleCloseModal = () => {
    queryClient.invalidateQueries('contact');
    queryClient.invalidateQueries('contacts');
    navigate('/');
  };

  const hasPos = sessionStorage.getItem('hasPos') === 'true';

  return isLoading ? (
    <SkeletonForm />
  ) : (
    <FormProvider {...createContactForm}>
      <UpsertForm onSubmit={onSubmit} isLoading={isLoading} />
      <ConfirmModal
        open={confirmOpen}
        title="Numero contatto:"
        subtitle={lastUpdateContact ? `${lastUpdateContact}` : 'N.D.'}
        text={`Questo numero identifica il contatto appena creato${
          hasPos ? '' : ', dovrà essere comunicato in cassa.'
        }`}
        confirmButtonText="Ok"
        onConfirm={() => handleCloseModal()}
      />
    </FormProvider>
  );
};
