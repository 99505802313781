import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Disclaimer } from '../Discalimer';
import { AuthContext } from './AuthProvider';

export const PrivateRoute = () => {
  const authContext = useContext(AuthContext);

  if (!authContext?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return sessionStorage.getItem('conferma_riservatezza') ? <Outlet /> : <Disclaimer />;
};
