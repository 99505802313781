import * as yup from 'yup';
import { ScanType } from '../../interface/ocr';

export const getOcrResultValidator = (scanType: ScanType | null) => {
  switch (scanType) {
    case ScanType.document:
      return yup.object().shape({
        nome: yup.string().required('Campo obbligatorio'),
        cognome: yup.string().required('Campo obbligatorio'),
        codice_fiscale: yup.string().required('Campo obbligatorio'),
      });
    case ScanType.receipt:
      return yup.object().shape({
        nome: yup.string().required('Campo obbligatorio'),
        cognome: yup.string().required('Campo obbligatorio'),
        importo: yup.string().required('Campo obbligatorio'),
      });
    default:
      return yup.object().shape({
        nome: yup.string().required('Campo obbligatorio'),
        cognome: yup.string().required('Campo obbligatorio'),
        codice_fiscale: yup.string().required('Campo obbligatorio'),
      });
  }
};
