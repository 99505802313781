import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';
import Lottie from 'lottie-react';
import InstructionAnimation from '../../assets/instruction.json';

type PopoverModalProps = {
  open: boolean;
  title?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  type: string | null;
  name: string | null;
};

const PopoverModal: React.FC<PopoverModalProps> = (props) => {
  const { open, title, type, name, onCancel = () => {}, onConfirm = () => {} } = props;

  const getTextByType = (type: string | null) => {
    switch (type) {
      case 'receipt':
        return 'Inquadra la ricevuta e premi il pulsante per fotografare.';
      case 'document':
        if (name === 'cie') {
          return "Inquadra il lato POSTERIORE della Carta d'identità e premi il pulsante per fotografare.";
        }
        return 'Inquadra il lato POSTERIORE della Tessera Sanitaria e premi il pulsante per fotografare.';
      default:
        return 'Inquadra il documento e premi il pulsante per fotografare.';
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} style={{ maxWidth: '500px', margin: '0 auto' }}>
      {title && <DialogTitle textAlign="center">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText textAlign={'center'}>{getTextByType(type)}</DialogContentText>
      </DialogContent>
      <Lottie animationData={InstructionAnimation} loop={true} autoplay={true} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};

export default PopoverModal;
