import { useMutation } from 'react-query';
import { createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import { login as loginApi } from '../../api/login';
import { AuthResponse, LoginData } from '../../interface/login';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface IAuthContext {
  isAuthenticated: boolean;
  token: string;
  idEvento: string;
  login: (data: LoginData) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token') || '';
  const idEvento = sessionStorage.getItem('idEvento') || '';

  const loginMutation = useMutation<AuthResponse, Error, LoginData>((data: LoginData) => loginApi(data), {
    onSuccess: (response, data) => {
      navigate('/');
      sessionStorage.setItem('username', data.username);
      sessionStorage.setItem('token', response.token);
      sessionStorage.setItem('refreshToken', response.refreshToken);
      sessionStorage.setItem('tokenExpirationDatetime', moment().add(2, 'hours').toISOString());
      sessionStorage.setItem('hasPos', String(response.pos));
      sessionStorage.setItem('idEvento', response.idEvento);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const login = async (data: LoginData) => {
    loginMutation.mutate(data);
  };

  const logout = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('conferma_riservatezza');
    sessionStorage.removeItem('tokenExpirationDatetime');
    sessionStorage.removeItem('hasPos');
    sessionStorage.removeItem('idEvento');
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        isLoading: loginMutation.isLoading,
        token,
        idEvento,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth deve essere utilizzato all interno di un AuthProvider');
  }

  return authContext;
};
