import { Controller, useFormContext } from 'react-hook-form';
import { scannedImmageResponse } from '../../../interface/ocr';
import styles from './OcrScannedResultCard.module.css';
import { TextField } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import moment from 'moment';

interface OcrScannedResultCardProps {
  scannedImageRes: scannedImmageResponse;
}
export default function OcrScannedResultCard({ scannedImageRes }: OcrScannedResultCardProps) {
  const { control } = useFormContext();

  const renderCardInfo = () => {
    switch (scannedImageRes.scanType) {
      case 'document':
        return (
          <div>
            <Controller
              name="nome"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label="Nome *"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="cognome"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label="Cognome *"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="data_nascita"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateField
                  InputLabelProps={{ shrink: true }}
                  label="Data di nascita *"
                  size="small"
                  {...field}
                  format="DD/MM/YYYY"
                  value={field.value ? moment(field.value) : null}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="codice_fiscale"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label="Codice Fiscale *"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        );
      case 'receipt':
        return (
          <>
            <div>
              <Controller
                name="nome"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    label="Nome *"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="cognome"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    label="Cognome *"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="importo"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    label="Importo *"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>
            <p>
              <span>Città:</span> {scannedImageRes.citta}
            </p>
            <p>
              <span>Cap:</span> {scannedImageRes.cap}
            </p>
            <p>
              <span>Provincia:</span> {scannedImageRes.provincia}
            </p>
            <p>
              <span>Email:</span> {scannedImageRes.mail}
            </p>
            <p>
              <span>Metodo pagamento:</span> {scannedImageRes.metodo_pagamento}
            </p>
          </>
        );
    }
  };

  return (
    <div className="ocr_person_card">
      <div className={styles.ocr_person_card}>
        <h2>Risultato scansione:</h2>
        <div className={styles.ocr_person_card_info}>{renderCardInfo()}</div>
      </div>
    </div>
  );
}
