import { ISummaryFormData } from '../../../interface/summary';

export const parseExternalEvents = (
  externalEvents: ISummaryFormData['qt_piazze_esterne'] | ISummaryFormData['qt_date_piazze_esterne'],
) => {
  const filteredPiazzeEsterne = externalEvents
    .filter((piazza) => {
      return piazza.nome !== '';
    })
    .map((piazza) => {
      return `${piazza.nome}: ${piazza.quantità};`;
    })
    .join('\n');

  return filteredPiazzeEsterne;
};
