import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { scannedImmageResponse } from '../../../interface/ocr';

interface ISetFormValues {
  keys: string[];
  setValue: UseFormSetValue<FieldValues>;
  valuesObj: scannedImmageResponse | null;
}
export const setFormValues = ({ keys, setValue, valuesObj }: ISetFormValues) => {
  if (valuesObj) {
    keys.forEach((key) => {
      setValue(key, valuesObj[key as keyof scannedImmageResponse]);
    });
  }
};
