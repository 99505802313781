import { Chip } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';

export const CodeChip = ({
  code,
  contactName,
  contactId,
}: {
  code?: number;
  contactName?: string;
  contactId?: number;
}) => {
  if (!code && !contactName) return null;

  const anonimo = contactId === Number(process.env.REACT_APP_ID_ANONIMO);
  return (
    <Chip
      variant="outlined"
      color="primary"
      icon={anonimo ? <FaceRetouchingOffIcon /> : <FaceIcon />}
      style={{ width: 'fit-content', margin: '0 auto', marginBottom: 20 }}
      label={
        <>
          {anonimo ? (
            <strong> ANONIMO</strong>
          ) : contactName ? (
            <strong>{contactName}</strong>
          ) : (
            <h1 style={{ fontSize: '1.5rem', textDecoration: 'none' }}>
              Codice contatto: <strong>{code}</strong>
            </h1>
          )}
        </>
      }
    />
  );
};
