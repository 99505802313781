import DonazioniListPresentational from '../components/donazioni/DonazioniListPresentational';
import NavBar from '../components/commons/NavBar';

export const Donazioni = () => {
  return (
    <>
      <DonazioniListPresentational />
      <NavBar />
    </>
  );
};
