import CodiceFiscale from 'codice-fiscale-js';

type ICodiceFiscaleObject = {
  name: string;
  surname: string;
  gender: 'F' | 'M';
  day: number;
  month: number;
  year: number;
  birthplace: string;
  birthplaceProvincia: string;
  cf?: string;
};

export default function codiceFiscaleReverse(code: string): ICodiceFiscaleObject | null {
  try {
    const cf = new CodiceFiscale(code);
    return cf.toJSON();
  } catch (e) {
    return null;
  }
}
