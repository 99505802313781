import React from 'react';
import { Box, Button } from '@mui/material';

export default function RendicontazioniButton({
  onClick,
  icon,
  label,
}: {
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
}) {
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={onClick}
      sx={{ boxShadow: 1, height: 'clamp(100px, 20vh, 250px)', fontWeight: 'bold' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box
          marginTop="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={42}
          height={42}
          borderRadius="50%"
          bgcolor="#0000000A"
        >
          {icon}
        </Box>
        <span>{label}</span>
      </Box>
    </Button>
  );
}
