import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDonazione } from '../api/createDonazione';
import { useAuth } from '../components/auth/AuthProvider';
import { useContact } from '../components/contacts/ContactProvider';
import { DonazioneForm } from '../components/donazioni/DonazioneForm';
import { schemaUpsertDonazione } from '../components/validation/schemaUpsertDonazione';
import { NuovaDonazione } from '../interface/donazione';

export const CreateDonazione = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { contact } = useContact();
  const location = useLocation();

  const createDonazioneForm = useForm({
    resolver: yupResolver(schemaUpsertDonazione),
    defaultValues: {
      metodo_pagamento: location.state?.receipt?.metodo_pagamento || 'contanti',
    },
  });
  const hasScannedReceipt = location.state?.receipt?.qty !== undefined;

  const { mutate: mutateCreate, isLoading } = useMutation<NuovaDonazione, Error, NuovaDonazione>(
    (data: NuovaDonazione) => {
      return createDonazione({ newDonazione: data, token, scansione_ricevuta: hasScannedReceipt });
    },
  );

  const onSubmit = (data: any) => {
    const newData = { ...data };
    delete newData.importo;

    mutateCreate(newData, {
      onSuccess: () => {
        toast.success('Donazione creata con successo');
        navigate('/');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const onCancel = () => {
    toast.info("L'inserimento della donazione è stato annullato");
    navigate('/');
  };

  return (
    <FormProvider {...createDonazioneForm}>
      <DonazioneForm contact={contact} onSubmit={onSubmit} onCancel={onCancel} isLoading={isLoading} />
    </FormProvider>
  );
};
