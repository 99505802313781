import * as yup from 'yup';

export const schemaUpsertDonazione = yup.object().shape({
  id: yup.number().nullable(),
  importo: yup
    .number()
    .min(0.01, "L'importo deve essere maggiore di 0")
    .required('Campo obbligatorio')
    .typeError('Campo obbligatorio'),
  metodo_pagamento: yup.string().required('Campo obbligatorio'),
});
