import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './components/auth/AuthProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ContactProvider } from './components/contacts/ContactProvider';
import { ProductProvider } from './components/products/ProductProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  palette: {
    primary: {
      main: '#00acde',
      contrastText: '#fff',
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'it'}>
        <HashRouter>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <ToastContainer />
              <ContactProvider>
                <ProductProvider>
                  <App />
                </ProductProvider>
              </ContactProvider>
            </ThemeProvider>
          </AuthProvider>
        </HashRouter>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
