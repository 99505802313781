import { Product, ProductToBuy } from '../interface/product';
import { ProdottoNuovaDonazione } from '../interface/donazione';
import productsFormatter from './lib/productsFormatter';
import getProductsToBuy from './lib/getProductsToBuy';
import calculateTotalToPay from './lib/calculateTotalToPay';

interface DonazioneState {
  importo: number;
  products: Product[];
  productsToBuy: ProductToBuy[];
  productsFormatted: ProdottoNuovaDonazione[];
  freeOffer: number;
  isAmountLess: boolean;
  totalToPay: number;
}

export type DonazioneAction =
  | { type: 'SET_IMPORTO'; payload: number }
  | { type: 'SET_PRODUCTS'; payload: { products: Product[]; scannedQuantity?: number } }
  | { type: 'SET_PRODUCTS_TO_BUY'; payload: ProductToBuy[] };

type Totals = { importo: number; totalToPay: number };

function getFreeOffer({ importo, totalToPay }: Totals): number {
  return importo - totalToPay > 0 ? importo - totalToPay : 0;
}

function getAmountLess({ importo, totalToPay }: Totals): boolean {
  return importo < totalToPay;
}

export default function donazioneReducer(state: DonazioneState, action: DonazioneAction): DonazioneState {
  let productsToBuy = [];
  let totalToPay = 0;
  let freeOffer = 0;

  switch (action.type) {
    case 'SET_IMPORTO':
      freeOffer = getFreeOffer({ importo: action.payload, totalToPay: state.totalToPay });
      return {
        ...state,
        importo: action.payload,
        freeOffer,
        isAmountLess: getAmountLess({ importo: action.payload, totalToPay: state.totalToPay }),
        productsFormatted: productsFormatter(state.productsToBuy),
      };

    case 'SET_PRODUCTS':
      productsToBuy = getProductsToBuy({
        products: action.payload.products,
        productsToBuy: state.productsToBuy,
        scannedQuantity: action.payload.scannedQuantity,
      });
      totalToPay = calculateTotalToPay(productsToBuy);
      freeOffer = getFreeOffer({ importo: totalToPay, totalToPay: totalToPay });
      return {
        ...state,
        products: action.payload.products,
        productsToBuy,
        totalToPay,
        freeOffer,
        isAmountLess: getAmountLess({ importo: totalToPay, totalToPay: totalToPay }),
        productsFormatted: productsFormatter(productsToBuy),
      };

    case 'SET_PRODUCTS_TO_BUY':
      productsToBuy = getProductsToBuy({ products: action.payload, productsToBuy: state.productsToBuy });
      totalToPay = calculateTotalToPay(action.payload);
      freeOffer = getFreeOffer({ importo: totalToPay, totalToPay: totalToPay });
      return {
        ...state,
        productsToBuy,
        importo: totalToPay,
        totalToPay,
        freeOffer,
        isAmountLess: getAmountLess({ importo: totalToPay, totalToPay: totalToPay }),
        productsFormatted: productsFormatter(productsToBuy),
      };

    default:
      return state;
  }
}
