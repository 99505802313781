import axios, { AxiosResponse } from 'axios';
import { NuovaDonazione } from '../interface/donazione';
import { refreshAccessToken } from './refreshToken';

const { REACT_APP_API_URL } = process.env;

export async function createDonazione({
  newDonazione,
  token,
  scansione_ricevuta,
}: {
  newDonazione: NuovaDonazione;
  token: string;
  scansione_ricevuta?: boolean;
}): Promise<NuovaDonazione> {
  await refreshAccessToken();
  try {
    const response: AxiosResponse<NuovaDonazione> = await axios.post(
      `${REACT_APP_API_URL}/donazioni`,
      { ...newDonazione, scansione_ricevuta },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        throw new Error('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      throw new Error('Errore sconosciuto: ' + error);
    }
  }
}
