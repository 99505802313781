import * as yup from 'yup';

export const schemaUpsertContact = yup.object().shape({
  nome: yup.string().required('Campo obbligatorio'),
  cognome: yup.string().required('Campo obbligatorio'),
  sesso: yup.string().nullable(),
  codice_contatto: yup.number().nullable(),
  codice_fiscale: yup
    .string()
    .nullable()
    .when(['riepilogo_donazioni'], {
      is: (riepilogoDonazioni: boolean) => riepilogoDonazioni,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere il riepilogo'),
      otherwise: (schema) => schema.notRequired(),
    }),
  data_nascita: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue) return new Date(originalValue);
      return null;
    })
    .max(new Date(), 'La data di nascita non può essere nel futuro'),
  luogo_nascita: yup.string().nullable(),
  provincia_nascita: yup.string().nullable(),

  indirizzo_email: yup
    .string()
    .email('Email non valida')
    .nullable()
    .when(['consenso_email', 'riepilogo_donazioni'], {
      is: (consensoEmail: boolean, riepilogoDonazioni: boolean) => consensoEmail || riepilogoDonazioni,
      then: (schema) =>
        schema.required('Campo obbligatorio se si vuole ottenere il riepilogo o informazioni via email'),
      otherwise: (schema) => schema.notRequired(),
    }),
  numero_telefono: yup
    .string()
    .matches(/^[0-9]*$/, 'Il numero di telefono può contenere solo numeri')
    .nullable()
    .when('consenso_telefono', {
      is: true,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere materiale informativo via telefono'),
      otherwise: (schema) => schema.notRequired(),
    }),

  // id_indirizzo: yup.number().nullable(),
  // id_nazione: yup.number().nullable(),
  // id_persona: yup.number().nullable(),

  indirizzo: yup
    .string()
    .nullable()
    .when('consenso_posta', {
      is: true,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere materiale informativo via posta'),
      otherwise: (schema) => schema.notRequired(),
    }),
  indirizzo_civico: yup.string().nullable(),
  citta: yup
    .string()
    .nullable()
    .when('consenso_posta', {
      is: true,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere materiale informativo via posta'),
      otherwise: (schema) => schema.notRequired(),
    }),
  cap: yup
    .string()
    .nullable()
    .when('consenso_posta', {
      is: true,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere materiale informativo via posta'),
      otherwise: (schema) => schema.notRequired(),
    }),
  provincia: yup
    .string()
    .nullable()
    .when('consenso_posta', {
      is: true,
      then: (schema) => schema.required('Campo obbligatorio se si vuole ottenere materiale informativo via posta'),
      otherwise: (schema) => schema.notRequired(),
    }),
  nazione: yup.string().nullable(),
  frazione: yup.string().nullable(),

  consenso_posta: yup.boolean().nullable(),
  consenso_email: yup.boolean().nullable(),
  consenso_telefono: yup.boolean().nullable(),
  consenso_comunicazioni_personalizzate: yup.boolean().nullable(),

  riepilogo_donazioni: yup.boolean().required('Campo obbligatorio'),
  id: yup.number().nullable(),
});
