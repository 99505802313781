import { scannedImmageResponse } from '../interface/ocr';
import { refreshAccessToken } from './refreshToken';
import { OCRSearchParams } from '../interface/ocr';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

interface scanImageParams {
  image: any;
  token: string;
  searchParams: OCRSearchParams;
}

export const scanImage = async ({ image, token, searchParams }: scanImageParams): Promise<scannedImmageResponse> => {
  await refreshAccessToken();
  const prasedImage = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');

  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/ocr`,
      { image: prasedImage },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...searchParams,
        },
      },
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        throw new Error('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      throw new Error('Errore sconosciuto: ' + error);
    }
  }
};
