/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

export function SkeletonContactList() {
  const skeletonContacts = [];

  for (let i = 0; i < 3; i++) {
    skeletonContacts.push(
      <Grid key={i} container>
        <Grid item xs>
          <Skeleton width="60%" height={40} />
          <Skeleton width="80%" />
          <Skeleton width="70%" />
        </Grid>
      </Grid>,
    );
  }

  return <>{skeletonContacts}</>;
}

export function SkeletonForm() {
  const skeletonContacts = [];

  for (let i = 0; i < 4; i++) {
    skeletonContacts.push(
      <Grid key={i} container>
        <Grid item xs style={{ marginBottom: 10 }}>
          <Skeleton width="30%" height={25} />
          <Skeleton width="100%" height={55} />

          <Skeleton width="45%" height={25} style={{ marginTop: 10 }} />
          <Skeleton width="100%" height={55} />
        </Grid>
      </Grid>,
    );
  }

  return <>{skeletonContacts}</>;
}

export function SkeletonReport() {
  return (
    <Stack spacing={2} width="100%">
      <Skeleton variant="rounded" height={75} />
      <Skeleton variant="rounded" height={75} />
      <Skeleton variant="rounded" height={75} />
    </Stack>
  );
}
