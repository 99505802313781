/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const Consensi = () => {
  const { control, setValue, watch } = useFormContext();

  const consenso_marketing = watch('consenso_marketing') === 'true' || watch('consenso_marketing') === true;

  const isAtLeastOneFieldChecked = !!(
    watch('consenso_posta') ||
    watch('consenso_telefono') ||
    watch('consenso_email') ||
    watch('consenso_comunicazioni_personalizzate')
  );

  useEffect(() => {
    // se aprendo il form c'è almeno un consenso allora l'utente vuole ricevere materiale informativo (spuntato il SI)
    setValue('consenso_marketing', isAtLeastOneFieldChecked);
  }, [
    watch('consenso_posta'),
    watch('consenso_telefono'),
    watch('consenso_email'),
    watch('consenso_comunicazioni_personalizzate'),
  ]);

  useEffect(() => {
    // se spunto NO allora tolgo il check a tutti
    if (!consenso_marketing && isAtLeastOneFieldChecked) {
      setValue('consenso_telefono', false);
      setValue('consenso_posta', false);
      setValue('consenso_email', false);
      setValue('consenso_comunicazioni_personalizzate', false);
    }
  }, [consenso_marketing]);

  useEffect(() => {
    // se spunto SI allora metto il check a telefono, posta e email
    if (consenso_marketing && !isAtLeastOneFieldChecked) {
      setValue('consenso_telefono', true);
      setValue('consenso_posta', true);
      setValue('consenso_email', true);
      setValue('consenso_comunicazioni_personalizzate', false);
    }
  }, [consenso_marketing]);

  return (
    <div>
      <h2>Consensi</h2>
      <p>
        Vuoi ricevere newsletter e comunicazioni da parte di AIRC per rimanere aggiornato su tutte le iniziative della
        Fondazione?
      </p>

      <FormControl>
        <Controller
          name="consenso_marketing"
          control={control}
          defaultValue={isAtLeastOneFieldChecked}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup {...field} row>
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
              {!!error && <span style={{ color: 'red' }}>{error.message}</span>}
            </>
          )}
        />
      </FormControl>

      {consenso_marketing && (
        <FormGroup>
          <FormControlLabel
            label="Tramite posta cartacea"
            control={
              <Controller
                name="consenso_posta"
                control={control}
                defaultValue={true}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
          />
          <FormControlLabel
            label="Tramite email"
            control={
              <Controller
                name="consenso_email"
                control={control}
                defaultValue={true}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
          />
          <FormControlLabel
            label="Tramite chiamata telefonica, sms o strumenti di messaggistica istantanea"
            control={
              <Controller
                name="consenso_telefono"
                control={control}
                defaultValue={true}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
          />
          <FormControlLabel
            label="Vuoi ricevere comunicazioni personalizzate sulla base dei tuoi interessi e delle tue abitudini?"
            control={
              <Controller
                name="consenso_comunicazioni_personalizzate"
                control={control}
                defaultValue={true}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
          />
        </FormGroup>
      )}
    </div>
  );
};
