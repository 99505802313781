import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';

export const NotFound = () => {
  return (
    <div>
      <img src={logo} className="logo" alt="logo" />

      <h1 style={{ margin: '50px 0' }}>404 Pagina non trovata</h1>
      <center>
        Torna alla <Link to={'/'}>home</Link>
      </center>
    </div>
  );
};
