import { ProdottoNuovaDonazione } from '../../interface/donazione';
import { ProductToBuy } from '../../interface/product';

export default function productsFormatter(productsToBuy: ProductToBuy[]): ProdottoNuovaDonazione[] {
  return productsToBuy
    .map((prodotto) => ({
      id_prodotto: prodotto.id_prodotto,
      quantita: prodotto.quantita,
      importo: prodotto.importo,
    }))
    .filter((prodotto) => prodotto.quantita > 0);
}
