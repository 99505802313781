import * as yup from 'yup';

export const schemaSummary = yup.object().shape({
  qt_ricevuti: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_distribuiti: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_distribuiti_digitale: yup.number(),

  qt_distribuiti_non_registrati: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .optional(),
  qt_non_utilizzabili: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_rimanenze: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_contributi_digitale: yup.number(),

  qt_contributi_non_registrati: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .optional(),

  tot_contributi: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_ricevute_cartacee: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_ricevute_digitali: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_richiesta_anno_successivo: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .default(0),

  qt_piazze_esterne: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          nome: yup.string().nullable(),
          quantità: yup
            .string()

            .nullable()
            .when('nome', {
              is: (nome: string) => nome !== '',
              then: () =>
                yup
                  .string()
                  .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
                  .required('Quantità del piazza esterna è obbligatoria'),
            }),
        })
        .required('Piazze esterne devono contenere almeno un elemento'),
    )
    .required('Piazze esterne è obbligatorio'),

  qt_date_piazze_esterne: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          nome: yup.string().nullable(),
          quantità: yup
            .string()

            .nullable()
            .when('nome', {
              is: (nome: string) => nome !== '',
              then: () =>
                yup
                  .string()
                  .matches(/^[0-9]*$/, 'Questo campo deve contenere solo numeri')
                  .required('Quantità del piazza esterna è obbligatoria'),
            }),
        })
        .required('Piazze esterne devono contenere almeno un elemento'),
    )
    .required('Piazze esterne è obbligatorio'),

  commenti: yup.string().trim(),

  orario_inizio: yup.date().typeError('Questo campo deve contenere una data valida').required(),

  orario_fine: yup.date().typeError('Questo campo deve contenere una data valida').required(),
});
