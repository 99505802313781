import { useLocation, useNavigate } from 'react-router-dom';
import OcrPresentational from '../components/ocr/OcrPresentational';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button } from '@mui/material';
import PopoverModal from '../components/ocr/PopoverModal';
import { useState } from 'react';

export const Ocr = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const scanType = searchParams.get('type');
  const scanName = searchParams.get('name');

  return (
    <div className="container-main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          className="back-button cursor-pointer"
          sx={{
            flex: 1,
            marginBottom: 1,
          }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon /> Indietro
        </Box>
        <PopoverModal
          open={openModal}
          title="Come si usa"
          onConfirm={() => setOpenModal(false)}
          type={scanType}
          name={scanName}
        />
        <Box sx={{ marginBottom: 1 }}>
          <Button variant="outlined" onClick={() => setOpenModal(true)}>
            Come si usa
          </Button>
        </Box>
      </Box>
      <OcrPresentational searchParams={{ type: scanType, name: scanName }} />
    </div>
  );
};
