import axios, { AxiosResponse } from 'axios';
import { SearchCriteria, ListContacts } from '../interface/contact';
import { refreshAccessToken } from './refreshToken';

const { REACT_APP_API_URL } = process.env;

export async function searchContacts(body: SearchCriteria, token: string): Promise<ListContacts> {
  await refreshAccessToken();

  try {
    const response: AxiosResponse<ListContacts> = await axios.post(`${REACT_APP_API_URL}/search/contacts`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        throw new Error('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      throw new Error('Errore sconosciuto: ' + error);
    }
  }
}
