import moment from 'moment';
import { Donazione } from '../../interface/donazione';
import DonazioneItem from './DonazioneItem';

interface Props {
  donazione: Donazione;
}

const DonazioneCard: React.FC<Props> = ({ donazione }) => {
  return (
    <div className="person-card">
      <div>
        <h3>
          {donazione.id} - {donazione.nome_contatto} {donazione.cognome_contatto}
        </h3>
        {donazione.prodotti && donazione.prodotti.map((prodotto, i) => <DonazioneItem key={i} prodotto={prodotto} />)}
        <p>
          Pagamento: <strong>{donazione.metodo_pagamento}</strong>
        </p>
        <p>
          Volontario: <strong>{donazione.username_volontario}</strong>
        </p>
        <p>
          Data: <strong>{donazione.created_at && moment(donazione.created_at).format('DD/MM/YYYY HH:mm')}</strong>
        </p>
      </div>
    </div>
  );
};

export default DonazioneCard;
