import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

const { REACT_APP_API_URL } = process.env;

export async function refreshAccessToken(): Promise<any> {
  const tokenExpirationDatetime = moment(sessionStorage.getItem('tokenExpirationDatetime'));
  const now = moment();
  const timeDifference = tokenExpirationDatetime.diff(now, 'minutes');

  if (timeDifference <= 60) {
    try {
      const response: AxiosResponse = await axios.post(`${REACT_APP_API_URL}/auth/token`, {
        refreshToken: sessionStorage.getItem('refreshToken'),
      });

      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('tokenExpirationDatetime', moment().add(2, 'hours').toISOString());
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('conferma_riservatezza');
        sessionStorage.removeItem('tokenExpirationDatetime');
        sessionStorage.removeItem('hasPos');
        window.location.href = '/#/login';
      } else {
        // eslint-disable-next-line no-console
        console.error('Errore nella chiamata API:', error);
      }
    }
  }
}
