import { createContext, useContext, ReactNode, useState } from 'react';
import { Contact } from '../../interface/contact';

type ContactContextType = {
  contact: Contact | null;
  setContact: (contact: Contact | null) => void;
};

const ContactContext = createContext<ContactContextType | undefined>(undefined);

type ContactProviderProps = {
  children: ReactNode;
};

export function useContact() {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error('useContact must be usend only inside ContactProvider');
  }
  return context;
}

export function ContactProvider({ children }: ContactProviderProps) {
  const [contact, setContact] = useState<Contact | null>(null);

  const contextValue: ContactContextType = {
    contact,
    setContact,
  };

  return <ContactContext.Provider value={contextValue}>{children}</ContactContext.Provider>;
}
