export const parseExternalEventsFromServer = (input: string) => {
  const lines = input.split('\n');

  const locations = lines.map((line) => {
    const separatorIndex = line.lastIndexOf(': ');

    const nome = line.substring(0, separatorIndex).trim();
    const quantità = parseInt(line.substring(separatorIndex + 1).trim(), 10) || 0;

    return { nome, quantità };
  });

  return locations;
};
