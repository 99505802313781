import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaLogin } from '../components/validation/schemaLogin';
import packageJSON from '../../package.json';
import { LoginData } from '../interface/login';

export const Login = () => {
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const { login, isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const { version } = packageJSON;

  useEffect(() => {
    // se sono già loggato mi porta alla home
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (data: LoginData) => {
    login(data);
  };

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schemaLogin),
    defaultValues: {
      username: searchParams.get('u') || '',
    },
  });

  return (
    <div className="container-box-main">
      <img src={logo} className="logo" alt="logo" />
      <div className="container-box">
        <h1>Accedi al tuo account</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField size="small" label="Username *" {...field} error={!!error} helperText={error?.message} />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size="small"
                label={'Password *'}
                type={showPassword ? 'text' : 'password'}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            style={{ marginTop: 20 }}
            startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
          >
            Accedi
          </Button>
        </form>
      </div>
      <div>
        <p className="slogan-text"> Rendiamo il cancro sempre più curabile</p>
        <p className="version">V {version}</p>
      </div>
    </div>
  );
};
