import Webcam from 'react-webcam';
import { useCallback, useRef, useState } from 'react';
import { videoConstraints } from '../../constants/webcam';
import Photobutton from '../ocr/PhotoButton/Photobutton';
import { Alert, Box, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

interface WebcamPhotoListCaptureProps {
  imagesList: any[];
  setImagesList: React.Dispatch<React.SetStateAction<any[]>>;
  maxImagesAllowed: number;
}

export default function WebcamPhotoListCapture({
  imagesList,
  setImagesList,
  maxImagesAllowed,
}: WebcamPhotoListCaptureProps) {
  const webcamRef = useRef<any>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot();
    setCapturedImage(image);
  }, [webcamRef, setCapturedImage]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {capturedImage ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
            <img src={capturedImage} alt="captured-img" />
            <Alert
              severity="info"
              sx={{
                textAlign: 'center',
              }}
            >
              Vuoi salvare la fotografia? Puoi sempre eliminarla in un secondo momento
            </Alert>
            <Box display="flex" gap={3}>
              <Button
                onClick={() => setCapturedImage(null)}
                sx={{ width: 120, backgroundColor: '#D32F2F' }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                Elimina
              </Button>
              <Button
                onClick={() => {
                  setImagesList((prev) => [...prev, capturedImage]);
                  setCapturedImage(null);
                }}
                sx={{ width: 120 }}
                variant="contained"
                startIcon={<CheckIcon />}
              >
                Salva
              </Button>
            </Box>
          </Box>
        ) : imagesList.length >= maxImagesAllowed ? (
          <Alert severity="error">
            Hai raggiunto il limite di immagini consentite. Per aggiungere un'altra immagine, elimina una delle
            precedenti.
          </Alert>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              style={{
                width: '100%',
              }}
            />
            <Photobutton onClick={capture} />
          </>
        )}
      </Box>
      {imagesList.length > 0 && !capturedImage ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
            overflowX: 'scroll',
            marginTop: 2,
          }}
        >
          {imagesList.map((img, imageIndex) => {
            return (
              <Box
                key={img}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  width: 75,
                  height: 75,
                }}
              >
                <ClearRoundedIcon
                  sx={{
                    position: 'absolute',
                    width: 20,
                    height: 20,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    top: 0,
                    right: 0,
                    boxShadow: '0 2px 4px rgba(0,0,0, 0.3)',
                  }}
                  color="primary"
                  onClick={() => setImagesList((prev) => prev.filter((_, i) => i !== imageIndex))}
                />
                <img style={{ maxWidth: '75px', maxHeight: '75px', objectFit: 'cover' }} src={img} alt="" />
              </Box>
            );
          })}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
