/* eslint-disable max-lines */
import { toast, ToastContainer } from 'react-toastify';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import WebcamPhotoListCapture from '../components/commons/WebcamPhotoListCapture';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { uploadImages } from '../api/uploadImages';
import { useAuth } from '../components/auth/AuthProvider';
import ConfirmModal from '../components/commons/ConfirmModal';
import { useQuery } from 'react-query';
import { countImagesForEvent } from '../api/countImagesForEvent';

const MAX_DDT_IMAGES = 5;

export const RendicontazioneDDT = () => {
  const [images, setImages] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { token, idEvento } = useAuth();
  const navigate = useNavigate();

  const { data: countData, isLoading: countLoading } = useQuery(
    ['fotoCounterDDT', idEvento],
    () => countImagesForEvent(idEvento, 'ddt', token),
    {},
  );

  const remainingImages = MAX_DDT_IMAGES - countData?.count;

  const handleConfirm = async () => {
    try {
      await uploadImages({ images, type: 'ddt' }, token);
      toast.success('Immagini caricate con successo!');
      setOpen(false);
      navigate(-1);
    } catch (err: any) {
      toast.error(err.message);
      setOpen(false);
    }
  };

  return (
    <div className="container-main">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          className="back-button cursor-pointer"
          sx={{
            flex: 1,
            marginBottom: 1,
          }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon />
          Indietro
        </Box>
        {remainingImages > 0 && (
          <Button
            disabled={images.length === 0}
            onClick={() => setOpen(true)}
            variant="contained"
            endIcon={<SendIcon />}
            sx={{ height: 30 }}
          >
            Invia
          </Button>
        )}
        <ConfirmModal
          open={open}
          title="Conferma"
          text="Sei sicuro di voler inviare le immagini selezionate? Una volta eseguita questa operazione, non sarà più possibile caricarne altre."
          backButtonText="Annulla"
          confirmButtonText="Invia"
          onCancel={() => setOpen(false)}
          onConfirm={() => handleConfirm()}
        />
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <center>
        <i>
          <small>Scatta le foto del documento di trasporto</small>
          <br />
          {countLoading ? (
            <></>
          ) : (
            <small>
              Hai già caricato {countData?.count} foto, ne hai a disposizione ancora {remainingImages}
            </small>
          )}
        </i>
      </center>
      <Box
        marginTop={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <WebcamPhotoListCapture imagesList={images} setImagesList={setImages} maxImagesAllowed={remainingImages} />
      </Box>
    </div>
  );
};
