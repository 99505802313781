/* eslint-disable max-lines */
import { toast, ToastContainer } from 'react-toastify';
import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../components/auth/AuthProvider';
import { updateSummary } from '../api/updateSummary';
import { useNavigate } from 'react-router-dom';
import { fetchSummary } from '../api/fetchSummary';

type MerceDanneggiataFormData = {
  qt_non_utilizzabili: number;
};

export const RendicontazioneMerceDanneggiata = () => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const { data: dataGetRendicontazione } = useQuery(['getRendiconrazione', token], () => fetchSummary(token), {
    onSuccess: (data) => {
      setValue('qt_non_utilizzabili', data.qt_non_utilizzabili);
    },
  });
  const merceDanneggiataForm = useForm<MerceDanneggiataFormData>({
    resolver: yupResolver(
      yup.object().shape({
        qt_non_utilizzabili: yup.number().required(),
      }),
    ),
    defaultValues: {
      qt_non_utilizzabili: dataGetRendicontazione?.qt_non_utilizzabili || 0,
    },
  });
  const { control, handleSubmit, setValue } = merceDanneggiataForm;

  const { mutate: mutateCreate } = useMutation<MerceDanneggiataFormData, Error, MerceDanneggiataFormData>(
    (data: MerceDanneggiataFormData) => {
      return updateSummary(data, token);
    },
  );

  const onSubmit = (data: MerceDanneggiataFormData) => {
    mutateCreate(data, {
      onSuccess: () => {
        toast.success("Quantita' merce danneggiata salvata con successo!");
        navigate('foto');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <div className="container-main">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 40,
              backgroundColor: '#eeeeee',
              padding: '24px 16px',
            }}
          >
            <Box width="100%" display="flex" alignContent="center" textAlign="center">
              <b>Inserisci la quantita' di merce danneggiata</b>
            </Box>
            <Controller
              name="qt_non_utilizzabili"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  type="number"
                  label="N. merce danneggiata"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
          <div style={{ marginTop: 60 }}>
            <Button variant="contained" type="submit">
              SCATTA UNA O PIU' FOTOGRAFIE
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};
