export default function toTitleCase(str: string): string {
  if (typeof str !== 'string') {
    return str;
  }

  // Dividi la stringa in parole utilizzando gli spazi come separatore
  const words = str.toLowerCase().split(' ');

  // Mappa ogni parola e trasforma la prima lettera in maiuscolo
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Unisci le parole nuovamente in una stringa
  const titleCaseStr = titleCaseWords.join(' ');

  return titleCaseStr;
}
