import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContactById } from '../api/getContact';
import { updateContact } from '../api/updateContact';
import { useAuth } from '../components/auth/AuthProvider';
import { useContact } from '../components/contacts/ContactProvider';
import { SkeletonForm } from '../components/commons/Skeleton';
import { SubmitOptions, UpsertForm } from '../components/contacts/UpsertForm';
import { schemaUpsertContact } from '../components/validation/schemaUpsertContact';
import { Contact, ContactExtended } from '../interface/contact';
import ConfirmModal from '../components/commons/ConfirmModal';

function removeNullUndefinedValues(obj: ContactExtended) {
  const { id, data_registrazione, consenso_marketing, ...rest } = obj;
  return Object.fromEntries(Object.entries(rest).filter(([key, value]) => value !== null && value !== undefined));
}

export const UpdateContact = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const contactId = searchParams.get('id') || '';
  const queryClient = useQueryClient();
  const { setContact } = useContact();
  const [lastUpdateContact, setLastUpdateContact] = useState<number>(0);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation<Contact, Error, Contact>((data: Contact) => {
    return updateContact(contactId, data, token);
  });

  const handleCommonActions = (
    data: any,
    options: SubmitOptions,
    navigate: Function,
    setContact: Function,
    contactId: string,
  ) => {
    if (options?.next === 'createDonazione') {
      setContact({ ...data, id: Number(contactId) });
      queryClient.invalidateQueries('contact');
      queryClient.invalidateQueries('contacts');
      navigate('/create-donazione', { state: location.state?.receipt && { receipt: location.state?.receipt } });
    } else {
      setLastUpdateContact(data.codice_contatto);
      setContact(null);
      setConfirmOpen(true);
    }
  };

  const onSubmit = (data: any, options: SubmitOptions) => {
    const cleanedData: Contact = removeNullUndefinedValues(data);

    if (!options?.isDirty) {
      // se il form non è stato modificato non faccio la mutation
      handleCommonActions(cleanedData, options, navigate, setContact, contactId);
    } else {
      mutateUpdate(cleanedData, {
        onSuccess: () => {
          toast.success('Contatto aggiornato con successo');

          handleCommonActions(cleanedData, options, navigate, setContact, contactId);
        },
        onError: (error: Error) => {
          toast.error(error.message);
        },
      });
    }
  };

  const handleCloseModal = () => {
    queryClient.invalidateQueries('contact');
    queryClient.invalidateQueries('contacts');
    navigate('/');
  };

  const {
    data: contactFromId,
    isLoading,
    error,
  } = useQuery(['contact', contactId], () => getContactById(contactId, token), {
    enabled: !!contactId,
    refetchOnWindowFocus: false,
  });

  const default_VAlues: Contact = {
    nome: '',
    cognome: '',
  };

  const updateContactForm = useForm({
    resolver: yupResolver(schemaUpsertContact as any, { stripUnknown: true }),
    defaultValues: contactFromId || default_VAlues,
  });

  useEffect(() => {
    if (contactFromId) {
      updateContactForm.reset(contactFromId);
    }
  }, [contactFromId, updateContactForm]);

  const hasPos = sessionStorage.getItem('hasPos') === 'true';

  return isLoading ? (
    <SkeletonForm />
  ) : error ? (
    <Alert severity="error"> Si è verificato un errore durante il caricaemnto. </Alert>
  ) : (
    <FormProvider {...updateContactForm}>
      <UpsertForm onSubmit={onSubmit} isLoading={isUpdating} />

      <ConfirmModal
        open={confirmOpen}
        title="Numero contatto:"
        subtitle={lastUpdateContact ? `${lastUpdateContact}` : 'N.D.'}
        text={`Questo numero identifica il contatto${hasPos ? '' : ', dovrà essere comunicato in cassa.'}`}
        confirmButtonText="Ok"
        onConfirm={() => handleCloseModal()}
      />
    </FormProvider>
  );
};
