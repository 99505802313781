import { useEffect, useReducer } from 'react';
import { Product, ProductToBuy } from '../interface/product';
import donazioneReducer from './donazioneReducer';
import { ProdottoNuovaDonazione } from '../interface/donazione';

interface DonazioneHookParams {
  initialImporto?: number;
  initialProducts?: Product[];
  onChangeImporto: (value: number) => void;
  onChangeProducts: (values: ProdottoNuovaDonazione[]) => void;
}

interface DonazioneHook {
  productsToBuy: ProductToBuy[];
  freeOffer: number;
  isAmountLess: boolean;
  totalToPay: number;
  setImporto: (value: number) => void;
  setProducts: (products: Product[], scannedQuantity?: number) => void;
  incrementQuantity: (id: number) => void;
  incrementQuantityByAmount: (id: number, amount: number) => void;
  decrementQuantity: (id: number) => void;
}

export default function useDonazione(params: DonazioneHookParams): DonazioneHook {
  const { initialImporto = 0, initialProducts = [], onChangeImporto, onChangeProducts } = params;

  const [state, dispatch] = useReducer(donazioneReducer, {
    importo: initialImporto,
    products: initialProducts,
    productsToBuy: [],
    productsFormatted: [],
    isAmountLess: false,
    freeOffer: 0,
    totalToPay: 0,
  });

  const { productsToBuy, productsFormatted, freeOffer, isAmountLess, importo, totalToPay } = state;

  const setImporto = (payload: number) => dispatch({ type: 'SET_IMPORTO', payload });
  const setProducts = (payload: Product[], scannedQuantity?: number) =>
    dispatch({ type: 'SET_PRODUCTS', payload: { products: payload, scannedQuantity } });

  const incrementQuantity = (id: number) => {
    const payload = productsToBuy.map((p) => {
      if (p.id === id) {
        p.quantita += 1;
      }
      return p;
    });
    dispatch({ type: 'SET_PRODUCTS_TO_BUY', payload });
  };

  const incrementQuantityByAmount = (id: number, amount: number) => {
    const payload = productsToBuy.map((p) => {
      if (p.id === id) {
        p.quantita = amount;
      }
      return p;
    });
    dispatch({ type: 'SET_PRODUCTS_TO_BUY', payload });
  };

  const decrementQuantity = (id: number) => {
    const payload = productsToBuy.map((p) => {
      if (p.id === id) {
        p.quantita -= 1;
      }
      return p;
    });
    dispatch({ type: 'SET_PRODUCTS_TO_BUY', payload });
  };

  useEffect(() => {
    onChangeProducts(productsFormatted);
    onChangeImporto(importo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsFormatted, importo]);

  return {
    productsToBuy,
    freeOffer,
    isAmountLess,
    totalToPay,
    setImporto,
    setProducts,
    incrementQuantity,
    incrementQuantityByAmount,
    decrementQuantity,
  };
}
