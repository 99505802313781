import { Button, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useAuth } from '../auth/AuthProvider';
import { scanImage } from '../../api/scanImage';
import Photobutton from './PhotoButton/Photobutton';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { OCRResult, ScanType, scannedImmageResponse } from '../../interface/ocr';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendOcrResult } from '../../api/sendOcrResult';
import { useOcrRedirect } from '../../hooks/useOcrRedirect';
import { OCRSearchParams } from '../../interface/ocr';
import { FormProvider, useForm } from 'react-hook-form';
import OcrScannedResultCard from './OcrScannedResultCard/OcrScannedResultCard';
import { getOcrResultValidator } from '../validation/schemaOcrResult';
import { setFormValues } from './utils/formValues';
import moment from 'moment';
import { videoConstraints } from '../../constants/webcam';

interface OcrPresentationalProps {
  searchParams: OCRSearchParams;
}

export default function OcrPresentational({ searchParams }: OcrPresentationalProps) {
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [scannedImageRes, setScannedImageRes] = useState<scannedImmageResponse | null>(null);
  const { token } = useAuth();
  const { resolveOcrRedirect } = useOcrRedirect();

  const ocrValidator = getOcrResultValidator(searchParams.type as ScanType);

  const ocrScannedResultForm = useForm({
    resolver: yupResolver<any>(ocrValidator),
  });

  useEffect(() => {
    const keys = Object.keys(ocrScannedResultForm.getValues());
    setFormValues({
      keys,
      setValue: ocrScannedResultForm.setValue,
      valuesObj: scannedImageRes,
    });
  }, [scannedImageRes, ocrScannedResultForm]);

  const mutateScanImage = useMutation<scannedImmageResponse, Error, void>({
    mutationFn: async () => {
      return await scanImage({ image: imgSrc, token, searchParams });
    },
    onSuccess: (data) => {
      setImgSrc(null);
      setScannedImageRes(data);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const mutateOcrResult = useMutation<OCRResult, Error, void>({
    mutationFn: async () => {
      if (!scannedImageRes) {
        throw new Error('Non è stato scansionato un documento');
      }
      const formValues = ocrScannedResultForm.getValues();

      if (formValues.data_nascita) {
        const { data_nascita } = formValues;
        formValues.data_nascita = moment(data_nascita).format('DD/MM/YYYY');
      }

      const payload = {
        ...scannedImageRes,
        ...formValues,
      };
      return await sendOcrResult(payload, token);
    },
    onSuccess: (ocrData) => {
      setScannedImageRes(null);
      resolveOcrRedirect(ocrData);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  if (scannedImageRes) {
    return (
      <>
        <FormProvider {...ocrScannedResultForm}>
          <OcrScannedResultCard scannedImageRes={scannedImageRes} />
        </FormProvider>
        <Button
          variant="contained"
          sx={{ marginTop: 3, width: '100%' }}
          onClick={() => mutateOcrResult.mutate()}
          disabled={mutateOcrResult.isLoading}
          startIcon={mutateOcrResult.isLoading && <CircularProgress size={16} color={'inherit'} />}
        >
          Cerca donatore
        </Button>
        <Button
          sx={{
            width: '100%',
            marginTop: 1,
          }}
          onClick={() => {
            setScannedImageRes(null);
          }}
          disabled={mutateOcrResult.isLoading}
        >
          RIPROVA
        </Button>
      </>
    );
  }

  return (
    <>
      {imgSrc ? (
        <>
          <img src={imgSrc} alt="img" />
          <Button
            variant="contained"
            sx={{ marginTop: 3, width: '100%' }}
            onClick={() => mutateScanImage.mutate()}
            startIcon={mutateScanImage.isLoading && <CircularProgress size={16} color={'inherit'} />}
            disabled={mutateScanImage.isLoading}
          >
            Cerca
          </Button>
          <Button
            sx={{
              width: '100%',
              marginTop: 1,
            }}
            onClick={() => {
              setImgSrc(null);
            }}
            disabled={mutateScanImage.isLoading}
          >
            RIPROVA
          </Button>
        </>
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            screenshotQuality={1}
            style={{
              width: '100%',
            }}
          />
          <Photobutton onClick={capture} />
        </>
      )}
    </>
  );
}
