import axios, { AxiosResponse } from 'axios';

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export type FormattedAddress = {
  nazione: string;
  provincia: string;
  citta: string;
  cap: string;
  indirizzo: string;
  indirizzo_civico: string;
};

export async function searchGoogleAddress(address: string): Promise<FormattedAddress | null> {
  try {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${REACT_APP_GOOGLE_API_KEY}`;

    const { data }: AxiosResponse<any> = await axios.get(apiUrl);
    if (data.status === 'OK' && data.results.length > 0) {
      const result = data.results[0];
      const formattedAddress: FormattedAddress = {
        nazione: '',
        provincia: '',
        citta: '',
        cap: '',
        indirizzo: '',
        indirizzo_civico: '',
      };

      const typesOfCities = [
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'administrative_area_level_6',
        'administrative_area_level_7',
        'locality',
      ];
      const typesOfAddress = ['route', 'street_address', 'town_square', 'intersection'];

      for (const component of result.address_components) {
        if (component.types.includes('country')) {
          formattedAddress.nazione = component.long_name;
        } else if (component.types.includes('administrative_area_level_2')) {
          formattedAddress.provincia = component.short_name;
        } else if (typesOfCities.some((type) => component.types.includes(type))) {
          formattedAddress.citta = component.long_name;
        } else if (component.types.includes('postal_code')) {
          formattedAddress.cap = component.short_name;
        } else if (typesOfAddress.some((type) => component.types.includes(type))) {
          formattedAddress.indirizzo = component.long_name;
        } else if (component.types.includes('street_number')) {
          formattedAddress.indirizzo_civico = component.long_name;
        }
      }

      return formattedAddress;
    }

    return null;
  } catch (e) {
    return null;
  }
}
