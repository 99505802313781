import { Product, ProductToBuy } from '../../interface/product';

export default function getProductsToBuy({
  products,
  productsToBuy,
  scannedQuantity,
}: {
  products: Product[];
  productsToBuy: ProductToBuy[];
  scannedQuantity?: number;
}) {
  return products
    .map((item) => {
      let totalQuantity = 0;
      const { quantita = 0 } = productsToBuy.find((itemToBuy) => itemToBuy.id_prodotto === item.id) || {};

      if (scannedQuantity && scannedQuantity > 0 && productsToBuy.length === 0) {
        if (item.nome === 'Donazione libera') totalQuantity = scannedQuantity + quantita;
      } else {
        totalQuantity = quantita;
      }
      return {
        ...item,
        id_prodotto: item?.id,
        quantita: totalQuantity,
        importo: Number(item.prezzo) * totalQuantity,
      };
    })
    .filter((item) => item !== null) as ProductToBuy[];
}
