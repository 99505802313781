import { useEffect, useState } from 'react';
import { Autocomplete, Button, CircularProgress, Divider, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import NavBar from '../components/commons/NavBar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaSearchContact } from '../components/validation/schemaSearchContact';
import { searchComuni } from '../api/searchComuni';
import { Comune } from '../interface/comune';
import { useContact } from '../components/contacts/ContactProvider';
import { IconLargeButton } from '../components/commons/IconLargeButton';
import { FaceRetouchingOff, CreditCard, DocumentScanner, Badge } from '@mui/icons-material';

interface FormData {
  nome: string;
  cognome: string;
  comuneResidenza: string;
}

export const SearchContact = () => {
  const { handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(schemaSearchContact),
  });

  const navigate = useNavigate();

  const { token } = useAuth();
  const [options, setOptions] = useState<Comune[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText] = useDebounce(searchText, 500);
  const { setContact } = useContact();
  const ID_ANONIMO = process.env.REACT_APP_ID_ANONIMO;

  useEffect(() => {
    async function search() {
      setIsLoadingOptions(true);
      const comuni = await searchComuni(debouncedSearchText, token);
      setOptions(comuni);
      setIsLoadingOptions(false);
    }

    if (debouncedSearchText && debouncedSearchText.length > 0) {
      search();
    } else {
      setOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const onSubmit = (data: FormData) => {
    navigate(`/results-people?nome=${data.nome.trim()}&cognome=${data.cognome.trim()}&citta=${data.comuneResidenza}`);
  };

  const donazioneAnonima = () => {
    setContact({ nome: 'Anonimo', id: Number(ID_ANONIMO) });
    navigate('/create-donazione');
  };

  return (
    <div className="container-main">
      <h1>Ricerca donatori</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="nome"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField size="small" label="Nome *" {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <Controller
          name="cognome"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField size="small" label="Cognome *" {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <Controller
          name="comuneResidenza"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              onChange={(event, value) => field.onChange(value?.comune || null)}
              onInputChange={(event, value) => setSearchText(value)}
              size="small"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(selectedValue) => selectedValue.comune}
              noOptionsText={isLoadingOptions ? <CircularProgress size={16} /> : 'Scrivi per cercare un comune'}
              options={options}
              disablePortal
              autoComplete
              renderInput={(params) => (
                <TextField {...params} label="Comune di residenza *" error={!!error} helperText={error?.message} />
              )}
            />
          )}
        />

        <Button variant="contained" type="submit" style={{ marginTop: 10 }}>
          Cerca
        </Button>

        <Divider className="my-25" />
        <Grid container spacing={2} marginTop={0.2}>
          {sessionStorage.getItem('hasPos') !== 'true' && (
            <Grid item xs={6}>
              <IconLargeButton
                text="Crea donazione anonima"
                icon={<FaceRetouchingOff fontSize="large" color="primary" />}
                onClick={() => donazioneAnonima()}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IconLargeButton
              text="Cerca con tessera sanitara"
              icon={<CreditCard fontSize="large" color="primary" />}
              onClick={() => {
                navigate('/ocr?type=document&name=ts');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <IconLargeButton
              text="Cerca con carta d'identità elettronica"
              icon={<Badge fontSize="large" color="primary" />}
              onClick={() => {
                navigate('/ocr?type=document&name=cie');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <IconLargeButton
              text="Scansiona ricevuta cartacea"
              icon={<DocumentScanner fontSize="large" color="primary" />}
              onClick={() => {
                navigate('/ocr?type=receipt&name=paper');
              }}
            />
          </Grid>
        </Grid>
      </form>

      <NavBar />
    </div>
  );
};
