/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaSummary } from '../components/validation/schemaSummary';
import SummaryTextFieldAccordion from '../components/summary/SummaryTextFieldAccordion';
import SummaryTextFieldAccordionTo from '../components/summary/SummaryTextFieldAccordionTo';
import { parseExternalEvents } from '../components/summary/utils/parseExternalEvents';
import { parseExternalEventsFromServer } from '../components/summary/utils/parseExternalEventsFromServer';
import { fetchSummary } from '../api/fetchSummary';
import { saveSummary } from '../api/saveSummary';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import { ISummaryData, ISummaryFormData } from '../interface/summary';
import { getMainProduct } from '../api/getMainProduct';
import { SkeletonForm } from '../components/commons/Skeleton';
import { sendSummary } from '../api/sendSummary';
import ConfirmModal from '../components/commons/ConfirmModal';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { getDigitalIncomes } from '../api/getDigitalIncomes';
import { DonazioneLibera, ProdottoDonazione } from '../interface/digitalIncomes';

const summaryDefaultValues = {
  qt_ricevuti: 0,
  qt_distribuiti_digitale: 0,
  qt_distribuiti_non_registrati: 0,
  qt_distribuiti: 0,
  qt_non_utilizzabili: 0,
  qt_rimanenze: 0,
  qt_contributi_digitale: 0,
  qt_contributi_non_registrati: 0,
  tot_contributi: 0,
  qt_ricevute_cartacee: 0,
  qt_ricevute_digitali: 0,
  qt_richiesta_anno_successivo: 0,
  qt_piazze_esterne: [{ nome: '', quantità: '' }],
  qt_date_piazze_esterne: [{ nome: '', quantità: '' }],
  commenti: '',
  orario_inizio: new Date(),
  orario_fine: new Date(),
};

export const RendicontazioneSummary = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { handleSubmit, control, reset, getValues, setValue } = useForm<ISummaryFormData>({
    resolver: yupResolver(schemaSummary),
    defaultValues: summaryDefaultValues,
  });
  const { token } = useAuth();
  const queryClient = useQueryClient();

  const { data: product, isLoading: isLoadingProduct } = useQuery(['main-product'], () => getMainProduct(token), {
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });

  const { data: rendicontazione, isFetching: isLoadingSummary } = useQuery(
    ['summary-data'],
    () => fetchSummary(token),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: 0,
      onSuccess: async (data) => {
        const digitalIncomes = await getDigitalIncomes(token);
        const _qtDistribuitiDigitale = digitalIncomes?.find((item) => item.tipologia === 'prodotto') as
          | ProdottoDonazione
          | undefined;
        const _qtImportoContributiDigitali = digitalIncomes?.find((item) => item.tipologia === 'donazione_libera') as
          | DonazioneLibera
          | undefined;
        const qtDistribuitiDigitale = _qtDistribuitiDigitale?.quantita_totale_prodotti ?? 0;
        const qtContributiDigitali = _qtImportoContributiDigitali?.importo_totale_donazione_libera ?? 0;
        const _qtDistribuitiNonRegistrati = Math.max(0, data.qt_distribuiti - qtDistribuitiDigitale);
        const _qtContributiNonRegistrati = Math.max(0, data.tot_contributi - qtContributiDigitali);
        const formData = {
          ...data,
          qt_piazze_esterne: parseExternalEventsFromServer(data.qt_piazze_esterne),
          qt_date_piazze_esterne: parseExternalEventsFromServer(data.qt_date_piazze_esterne),
          qt_distribuiti_digitale: qtDistribuitiDigitale,
          qt_contributi_digitale: qtContributiDigitali,
          qt_distribuiti_non_registrati: _qtDistribuitiNonRegistrati,
          qt_contributi_non_registrati: _qtContributiNonRegistrati,
        };

        reset(formData as any);
      },
    },
  );

  const { mutate: mutateCreate, isLoading } = useMutation<ISummaryData, Error, ISummaryData>((data: ISummaryData) => {
    return saveSummary(data, token);
  });

  const mainProduct = useMemo(() => {
    if (product) {
      return product.nome.split(' ')[0];
    } else {
      return 'Prodotti';
    }
  }, [product]);

  const setWordGender = (word: string) => {
    if (mainProduct?.endsWith('e')) {
      return word.substring(0, word.length - 1) + 'e';
    } else {
      return word;
    }
  };

  const closedEvent = !!rendicontazione?.sent;

  const onSubmit = (summaryFormdata: ISummaryFormData) => {
    const { qt_piazze_esterne, qt_date_piazze_esterne } = summaryFormdata;
    const parsedExternalEvents = parseExternalEvents(qt_piazze_esterne);
    const parsedExternalEventsTo = parseExternalEvents(qt_date_piazze_esterne);

    const data = {
      ...summaryFormdata,
      qt_piazze_esterne: parsedExternalEvents,
      qt_date_piazze_esterne: parsedExternalEventsTo,
    };

    delete data.qt_distribuiti_digitale;
    delete data.qt_distribuiti_non_registrati;
    delete data.qt_contributi_digitale;
    delete data.qt_contributi_non_registrati;

    mutateCreate(data, {
      onSuccess: () => {
        toast.success('La rendicontazione è salvata in bozza! Puoi modificarla quando vuoi.');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const checkTotalQuantity = (values: ISummaryFormData) => {
    const {
      qt_ricevuti,
      qt_piazze_esterne,
      qt_distribuiti,
      qt_non_utilizzabili,
      qt_rimanenze,
      qt_date_piazze_esterne,
    } = values;

    const piazzeEsterneTotalIn = qt_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countIn = qt_ricevuti + piazzeEsterneTotalIn;

    const piazzeEsterneTotalOut = qt_date_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countOut = qt_non_utilizzabili + qt_distribuiti + qt_rimanenze + piazzeEsterneTotalOut;

    return countIn === countOut;
  };

  const onCloseEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const handleCloseEvent = async () => {
    try {
      const formValues = getValues();
      if (!checkTotalQuantity(formValues)) {
        toast.error('Il totale delle quantità in ingresso non corrisponde al totale delle quantità in uscita!');
        return;
      }
      await sendSummary(token);
      toast.success("Hai chiuso l'evento! La rendicontazione sarà inviata agli uffici territoriali.");
      queryClient.invalidateQueries({ queryKey: ['summary-data'] });
      setConfirmOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const watchedFields = useWatch({
    control,
    name: [
      'qt_distribuiti_non_registrati',
      'qt_contributi_non_registrati',
      'qt_distribuiti_digitale',
      'qt_contributi_digitale',
    ],
  });

  const [qtDistribuitiNonRegistrati, qtContributiNonRegistrati, qtDistribuitiDigitale, qtContributiDigitali] =
    watchedFields;

  useEffect(() => {
    const sommaDistribuiti = Number(qtDistribuitiDigitale || 0) + Number(qtDistribuitiNonRegistrati || 0);
    const sommaContributi = Number(qtContributiDigitali || 0) + Number(qtContributiNonRegistrati || 0);

    setValue('qt_distribuiti', sommaDistribuiti);
    setValue('tot_contributi', sommaContributi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtContributiNonRegistrati, qtDistribuitiNonRegistrati]);

  if (isLoadingProduct || isLoadingSummary) {
    return <SkeletonForm />;
  }

  return (
    <div className="container-main">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <h1>Rendicontazione Allegato B</h1>

      {closedEvent && (
        <center>
          <i>
            <small>Evento chiuso non modificabile</small>
          </i>
        </center>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#f3f6f4',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Prodotto</small>
          </i>
          <Controller
            name="qt_ricevuti"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Ricevuti')}`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_distribuiti_digitale"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Distribuiti')} Con Incasso Digitale`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_distribuiti_non_registrati"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Distribuiti')} Con Donazione Non Registrata a Donandi`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_distribuiti"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Distribuiti')}`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_non_utilizzabili"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} Non Utilizzabili`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_rimanenze"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Rimanenze"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <SummaryTextFieldAccordion
            accordionSummaryTitle={`${mainProduct} ${setWordGender('Presi')} da altre piazze`}
            control={control}
            disabled={closedEvent}
          />
          <SummaryTextFieldAccordionTo
            accordionSummaryTitle={`${mainProduct} ${setWordGender('Dati')} ad altre piazze`}
            control={control}
            disabled={closedEvent}
          />
          <Controller
            name="qt_richiesta_anno_successivo"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Richiesti')} per Il ${new Date().getFullYear() + 1}`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#f3f6f4',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Contributi</small>
          </i>
          <Controller
            name="qt_contributi_digitale"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Contributi Raccolti Con Incasso Digitale"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_contributi_non_registrati"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Contributi Raccolti Con Donazione Non Registrata a Donandi"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="tot_contributi"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                type="number"
                label="Contributi raccolti"
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#f3f6f4',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Ricevute</small>
          </i>
          <Controller
            name="qt_ricevute_cartacee"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Ricevute Cartacee Utilizzate"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_ricevute_digitali"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Ricevute Digitali Utilizzate"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#f3f6f4',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Evento</small>
          </i>
          <Controller
            name="commenti"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                multiline
                minRows={2}
                size="small"
                label="Commenti..."
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="orario_inizio"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label="Ora inizio distribuzione *"
                format="HH:mm"
                ampm={false}
                {...field}
                value={moment(field.value)}
              />
            )}
          />
          <Controller
            name="orario_fine"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label="Ora fine distribuzione *"
                format="HH:mm"
                ampm={false}
                timezone="Europe/Rome"
                {...field}
                value={moment(field.value)}
              />
            )}
          />
        </div>
        {closedEvent && (
          <center>
            <i>
              <small>Evento chiuso non modificabile</small>
            </i>
          </center>
        )}

        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || closedEvent}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
        >
          Salva
        </Button>
        <Button
          variant="outlined"
          type="button"
          disabled={isLoading || closedEvent || !rendicontazione}
          style={{ marginBottom: 20 }}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
          onClick={onCloseEvent}
        >
          Chiudi evento
        </Button>
      </form>

      <ConfirmModal
        open={confirmOpen}
        title="Vuoi chiudere l'evento?"
        text="Chiudendo l'evento la rendicontazione sarà inviata agli uffici territoriali. Se chiudi l'evento non potrai riaprirlo."
        confirmButtonText="Chiudi evento"
        backButtonText="Annulla"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCloseEvent()}
      />
    </div>
  );
};
