/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import { Donazione } from '../interface/donazione';
import { refreshAccessToken } from './refreshToken';

const { REACT_APP_API_URL } = process.env;

export async function searchDonazioni(
  token: string,
  formattedDonazioniDate: string,
  page: number,
  perPage: number,
): Promise<{ data: Donazione[]; totalCount: number }> {
  await refreshAccessToken();

  try {
    const offset = (page - 1) * perPage;

    const response: AxiosResponse<Donazione[]> = await axios.get(
      `${REACT_APP_API_URL}/donazioni?date=${formattedDonazioniDate}&limit=${perPage}&offset=${offset}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const totalCount = Number(response.headers['x-total-count']);

    return {
      data: response.data,
      totalCount,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        console.log('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      console.log('Errore sconosciuto: ' + error);
    }

    return {
      data: [],
      totalCount: 0,
    };
  }
}
