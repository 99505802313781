import { ReactElement } from 'react';

export const IconLargeButton = ({ text, icon, onClick }: { text: string; icon: ReactElement; onClick: () => void }) => {
  return (
    <div className="icon-button" onClick={onClick}>
      {icon}
      <span> {text}</span>
    </div>
  );
};
